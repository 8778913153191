module.exports = {
  categories: [
    {
      label: 'Секс',
      value: 'sex'
    },
    {
      label: 'Тіло',
      value: 'body'
    },
    {
      label: 'Стосунки',
      value: 'relationships'
    }
  ],
  sex: {
    perPage: 10
  },
  body: {
    perPage: 10
  },
  relationships: {
    perPage: 10
  },
  addThis: {
    id: 'ra-5bd037984fa57551'
  },
  subscribeApiUrl: 'https://vpershe-website-production-default-rtdb.europe-west1.firebasedatabase.app/subscribers.json',
  questionApiUrl: 'https://vpershe-website-production-default-rtdb.europe-west1.firebasedatabase.app/questions.json',
};
